<template>
  <div class="service-container">
    <div class="g-section">
      <div class="service-banner">
        放一张banner
      </div>
    </div>
    <div class="g-section">
      <div class="g-wrap service-list">
        <div class="service-item">
          <el-avatar :size="100" src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"></el-avatar>
          <p class="service-title">人才需求</p>
          <p>服务简短说明服务简短说明服务简短说明服务简短说明服务简短说明服务简短说明</p>
          <el-button type="primary" plain size="small">GO<i class="el-icon-d-arrow-right el-icon--right"></i></el-button>
        </div>
         <div class="service-item">
          <el-avatar :size="100" src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"></el-avatar>
          <p class="service-title">生涯规划</p>
          <p>服务简短说明服务简短说明服务简短说明服务简短说明服务简短说明服务简短说明</p>
          <el-button type="primary" plain size="small">GO<i class="el-icon-d-arrow-right el-icon--right"></i></el-button>
        </div>
         <div class="service-item">
          <el-avatar :size="100" src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"></el-avatar>
          <p class="service-title">教育培训</p>
          <p>服务简短说明服务简短说明服务简短说明服务简短说明服务简短说明服务简短说明</p>
          <el-button type="primary" plain size="small">GO<i class="el-icon-d-arrow-right el-icon--right"></i></el-button>
        </div>
         <div class="service-item">
          <el-avatar :size="100" src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"></el-avatar>
          <p class="service-title">职业测评</p>
          <p>服务简短说明服务简短说明服务简短说明服务简短说明服务简短说明服务简短说明</p>
          <el-button type="primary" plain size="small">GO<i class="el-icon-d-arrow-right el-icon--right"></i></el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.service-container {
  display: flex;
  flex-direction: column;
  background-color: #f6f6f8;
  flex: 1;
}
.service-banner {
  display: flex;
  width: 100%;
  height: 200px;
  background-color: #d3dfe8;
  justify-content: center;
  align-items: center;
}
.g-section {
  background-color: #f6f6f8;
}
.service-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
}
.service-item {
  display: flex;
  flex-direction: column;
  width: 260px;
  height: 360px;
  justify-content: center;
  border-radius: 3px;
  align-items: center;
  margin-bottom: 20px;
  padding: 20px;
  box-sizing: border-box;
  background-color: #ffffff;
  cursor: pointer;
  &:hover {
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.05);
  }
  .service-title{
    font-size: 16px;
    font-weight: 600;
  }
}
</style>